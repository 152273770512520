import React from 'react'

import Button from '../../components/Button/Button'
import Container from '../../components/Container/Container'
import Layout from '../../components/Layout/Layout'
import Section from '../../components/Section/Section'

import './index.css'

const StyleGuide = () => (
  <Layout>
    <Container>
      <Section>
        <h3>Typography</h3>

        <h5 className="StyleGuide--title">
          <strong>H1</strong> 48px-60px / 1.0
    </h5>
        <h1 style={{ maxWidth: '610px' }}>A Community for Black People in Technology</h1>

        <h5 className="StyleGuide--title">
          <strong>H2</strong> 36px-48px / 1.0
    </h5>
        <h2 style={{ maxWidth: '580px' }}>A Community for Black People in Technology</h2>

        <h5 className="StyleGuide--title">
          <strong>H3</strong> 30px-36px / 1.0
    </h5>
        <h3 style={{ maxWidth: '400px' }}>A Community for Black People in Technology</h3>

        <h5 className="StyleGuide--title">
          <strong>blockquote/.font-large</strong> 24-30px / 1.4
    </h5>

        <blockquote style={{ maxWidth: '760px' }}>Melanin.Tech is a networking and community building platform meant to <em>inspire</em> and <strong>encourage</strong> techies of color in every aspect of life. </blockquote>

        <h5 className="StyleGuide--title">
          <strong>Body</strong> 16-20px / 1.8
    </h5>
        <p style={{ maxWidth: '505px' }}>Melanin.Tech is a networking and community building platform meant to <em>inspire</em> and <strong>encourage</strong> techies of color in every aspect of life.</p>

        <h5 className="StyleGuide--title">
          <strong>.font-small</strong> 12-15px / 1.8
    </h5>
        <p className="font-small" style={{ maxWidth: '400px' }}>Melanin.Tech is a networking and community building platform meant to <em>inspire</em> and <strong>encourage</strong> techies of color in every aspect of life.</p>

        <hr />

        <h3>Colors</h3>

        <h5 className="StyleGuide--title">
          <strong>Core</strong> Palette
    </h5>
        <div style={{ display: 'flex', marginBottom: '50px' }}>
          <div
            style={{
              flex: '0 0 107px',
              marginRight: '30px',
              height: '107px',
              background: `var(--color-melanin)`,
              borderRadius: '50%',
            }}
            title="Melanin `var(--color-melanin)`"
          />
          <div
            style={{
              flex: '0 0 107px',
              marginRight: '30px',
              height: '107px',
              background: `var(--color-midtone)`,
              borderRadius: '50%',
            }}
            title="Midtone `var(--color-midtone)`"
          />
          <div
            style={{
              flex: '0 0 107px',
              marginRight: '30px',
              height: '107px',
              background: `var(--color-actionable)`,
              borderRadius: '50%',
            }}
            title="Actionable `var(--color-actionable)`"
          />
          <div
            style={{
              flex: '0 0 107px',
              marginRight: '30px',
              height: '107px',
              background: `var(--color-light)`,
              borderRadius: '50%',
            }}
            title="Light `var(--color-light)`"
          />
        </div>

        <h5 className="StyleGuide--title">
          <strong>Accent</strong> Palette
    </h5>
        <div style={{ display: 'flex', marginBottom: '50px' }}>
          <div
            style={{
              flex: '0 0 107px',
              marginRight: '30px',
              height: '107px',
              background: `var(--color-accent-r)`,
              borderRadius: '50%',
            }}
            title="Accent Red `var(--color-accent-r)`"
          />
          <div
            style={{
              flex: '0 0 107px',
              marginRight: '30px',
              height: '107px',
              background: `var(--color-accent-o)`,
              borderRadius: '50%',
            }}
            title="Accent Orange `var(--color-accent-o)`"
          />
          <div
            style={{
              flex: '0 0 107px',
              marginRight: '30px',
              height: '107px',
              background: `var(--color-accent-y)`,
              borderRadius: '50%',
            }}
            title="Accent Yellow `var(--color-accent-y)`"
          />
          <div
            style={{
              flex: '0 0 107px',
              marginRight: '30px',
              height: '107px',
              background: `var(--color-accent-g)`,
              borderRadius: '50%',
            }}
            title="Accent Green `var(--color-accent-g)`"
          />
          <div
            style={{
              flex: '0 0 107px',
              marginRight: '30px',
              height: '107px',
              background: `var(--color-accent-b)`,
              borderRadius: '50%',
            }}
            title="Accent Blue `var(--color-accent-b)`"
          />
          <div
            style={{
              flex: '0 0 107px',
              marginRight: '30px',
              height: '107px',
              background: `var(--color-accent-v)`,
              borderRadius: '50%',
            }}
            title="Accent Violet `var(--color-accent-v)`"
          />
          <div
            style={{
              flex: '0 0 107px',
              marginRight: '30px',
              height: '107px',
              background: `var(--color-accent-pink)`,
              borderRadius: '50%',
            }}
            title="Accent Pink `var(--color-accent-pink)`"
          />
        </div>

        <hr />

        <h3>Components</h3>

        <div style={{ display: 'flex' }}>
          <div style={{ flex: '0 0 50%' }}>
            <h5 className="StyleGuide--title">
              <strong>Button</strong>
            </h5>
            <Button href="/">Primary Button</Button>
          </div>
          <div style={{ flex: '0 0 50%' }}>
            <h5 className="StyleGuide--title">
              Secondary <strong>Button</strong>
            </h5>
            <Button className="secondary" href="/">Secondary Button</Button>
          </div>
        </div>
      </Section>
    </Container>
  </Layout>
)

export default StyleGuide
